<script>
import PropostaList from '@/components/shared/proposta/PropostaList.vue'

export default {
  extends: PropostaList,

  methods: {
    getPropostas() {
      const consignataria = JSON.parse(
        localStorage.getItem('consignatariaSelecionada'),
      )
      this.propostaService
        .getPropostasDaMatriculaEConsignatariaSelecionada(
          this.idMatricula,
          consignataria.id,
        )
        .then((res) => {
          this.propostas = res
        })
        .catch((err) => {
          this.$toast.add({
            severity: 'error',
            summary: err.response.data.message,
            life: 10000,
          })
        })
    },
  },
}
</script>
